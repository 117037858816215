import React from "react";
import { graphql } from "gatsby";
import { BlogPostPage } from "../components/post-page";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { CardContext } from "../components/card/context";
import { PREFER_BLOG, suggest } from "../lib/suggestions";

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    doc: sanityPost(id: { eq: $id }) {
      _type
      id
      publishedAt
      readingTimeInMinutes
      categories {
        _id
        _type
        title
        titlePublic
        slug {
          current
        }
        status
        mainImage {
          ...SanityImage
          alt
        }
      }
      mainImage {
        ...SanityImage
        alt
      }
      cardImage {
        ...SanityImageSimple
      }
      mainVideo {
        ...externalVideo
      }
      mainAudio {
        asset {
          url
        }
      }
      duration
      title
      slug {
        current
      }
      isFeatured
      status
      hideMainMedia
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 12 })
      readingTimeInMinutes
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
          url
          title
          companies {
            title
          }
        }
      }
      promo {
        title
        _rawIntro(resolveReferences: { maxDepth: 5 })
        _rawOffer(resolveReferences: { maxDepth: 5 })
        buttonText
        file {
          asset {
            url
            originalFilename
            size
          }
        }
        externalUrl
        previewImage {
          ...SanityImageSimple
        }
      }
      relatedPosts {
        ...cardNode
      }
      # change when stories launches
      # relatedStories {
      #   ...cardNodeStory
      # }
      companies {
        title
        mainImage {
          ...SanityImageSimple
        }
      }
    }
    recentPosts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: [DESC] }
      filter: {
        slug: { current: { ne: null } }
        isPublished: { eq: true }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

const BlogPostTemplate = ({ data }) => (
  <LayoutContainer doc={data.doc}>
    <PageHeadMeta
      title={`${data.doc.title}${
        !data.doc.hideMainMedia && data.doc.mainVideo && data.doc.mainVideo.url
          ? " [video]"
          : ""
      }`}
      doc={data.doc}
    />
    {data.doc && (
      <CardContext.Provider value={{ ...data.doc }}>
        <BlogPostPage {...data.doc} suggest={suggest(data, PREFER_BLOG)} />
      </CardContext.Provider>
    )}
  </LayoutContainer>
);

export default BlogPostTemplate;
